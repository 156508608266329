import styled from '@emotion/styled'
import { Circle } from 'app/components/Icons'
import { motion } from 'framer-motion'
import React, { memo } from 'react'

export interface Props {
  label?: string
}

export const Service = memo(function Service({ label }: Props) {
  if (!label) {
    return null
  }

  return (
    <Container>
      <Circle />
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 50%;
  margin-top: 1.875rem;
  padding-right: 1.875rem;

  > svg {
    fill: ${({ theme }) => theme.colors.variants.primaryLight2};
    height: 0.5rem;
    margin-right: 0.625rem;
  }

  @media (max-width: 767px) {
    padding-right: 0;
  }

  @media (max-width: 474px) {
    width: 100%;
    margin-top: 0.875rem;
  }
`

const Label = styled.div`
  width: calc(100% - 3.125rem);
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 300;
  line-height: 1.4375rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

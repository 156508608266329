import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniqBy } from 'lodash'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const RoomsList = memo(function RoomsList({ rooms }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container row tag="section" wrap dial={5} space="between">
      {uniqBy(rooms, 'title').map((item, index) => (
        <Room key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  padding: 0 7.292vw;
  margin: 3.125rem auto 9.375rem;

  @media (max-width: 1199px) {
    padding: 0 1.875rem;
    margin: 1.875rem auto 5.625rem;
  }

  @media (max-width: 767px) {
    margin: 0 0 5.625rem 0;
  }
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import React, { memo } from 'react'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  services: ServiceProps[]
  title?: string
}

export const RoomsServices = memo(function RoomsServices({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container dial={5} row stretch tag="section" wrap>
      <LeftSide>
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        <Line className="line" variant="compact" />
      </LeftSide>
      <RightSide>
        <Services className="services-list" row wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin: 9.375rem 0 0;
  padding: 6.25rem 0;

  @media (max-width: 1199px) {
    margin-top: 5.625rem;
    padding: 5rem 1.875rem;
  }

  @media (max-width: 1023px) {
    .line {
      margin-right: auto;
      margin-left: auto;
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-left: 14.583vw;

  @media (max-width: 1199px) {
    padding-left: 1.875rem;
  }

  @media (max-width: 1023px) {
    display: block;
    width: 100%;
    text-align: center;
  }
`

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.125rem;
  max-width: 18.75rem;

  b {
    font-style: italic;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.variants.primaryLight2};
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.75rem;
  }

  @media (max-width: 1023px) {
    max-width: none;
  }
`

const Services = styled(FlexBox)`
  margin-top: -1.875rem;
`

import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Arrow } from 'app/components/Common/Arrow'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Spinner } from 'app/components/Common/Spinner'
import { Circle } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  beButtonURL?: string
  images: ImageProps[]
  languageCode: string
  pax?: string
  beds?: string
  title?: string
}

export const Room = memo(function Room({
  beButtonURL,
  images,
  languageCode,
  pax,
  beds,
  title,
}: Props) {
  if (!title) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = React.useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      defaultAnimation: {
        duration: 2000,
      },
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        if (images) {
          images.length > 1 ? setDetails(s.track.details) : null
        }
      },
    },
    [],
  )

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Wrapper>
        {pax || beds ? (
          <Info dial={4} row wrap>
            {pax ? <Pax>{pax}</Pax> : null}
            <Circle />
            {beds ? <Beds>{beds}</Beds> : null}
          </Info>
        ) : null}

        <Slider ref={sliderRef}>
          {uniq(images).map((item, index) => (
            <Slide key={index} className="keen-slider__slide">
              <Inner style={positionStyle(index)}>
                {loaded[index] ? (
                  <Image media="(min-width: 2800px)" {...item} />
                ) : (
                  <Spinner />
                )}
              </Inner>
            </Slide>
          ))}
        </Slider>

        {images.length > 1 ? (
          <Arrows row>
            <Arrow
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.prev()
              }
            />
            <Arrow
              direction="R"
              onClick={(e) =>
                e.stopPropagation() || instanceRef.current?.next()
              }
            />
          </Arrows>
        ) : null}
      </Wrapper>
      {title ? (
        <Title>
          <Label>{title}</Label>
        </Title>
      ) : null}
      {beButtonURL ? (
        <CTA
          className="room-cta"
          label={useVocabularyData('check-availability', languageCode)}
          target="_blank"
          URL={beButtonURL}
          variant="simple"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 6.25rem;

  @media (max-width: 1199px) {
    width: calc(50% - 0.9375rem);
    margin-top: 3.75rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 5.625rem;
    &:first-of-type {
      margin-top: 3.75rem;
    }
  }
`

const Wrapper = styled.div`
  margin-bottom: 2.5rem;
  position: relative;

  @media (max-width: 1199px) {
    margin-bottom: 1.875rem;
  }
`

const Info = styled(FlexBox)`
  position: absolute;
  align-items: center;
  top: 1.875rem;
  left: 1.875rem;
  z-index: 3;
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1rem;

  > svg {
    margin: 0 0.75rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    height: 0.5rem;
  }
`

const Beds = styled.div``

const Pax = styled.div``

const Slider = styled.div`
  display: flex;
  height: 48.9vh;
  max-height: 27.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  outline: none;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.6)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    max-height: 25rem;
  }

  @media (max-width: 1023px) {
    max-height: 18.75rem;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Arrows = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
`

const Title = styled.div``

const Label = styled.span`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2rem;
  position: relative;
  display: inline-block;

  &:after {
    content: '';
    width: 3rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight2};
    position: absolute;
    right: -4.25rem;
    top: 50%;
    margin-top: -0.0625rem;
  }

  @media (max-width: 1199px) {
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 2.25rem;
    &:after {
      top: 0.875rem;
      margin-top: 0.125rem;
    }
  }

  @media (max-width: 1023px) {
    padding-right: 0;
    &:after {
      display: none;
    }
  }
`

const Image = styled.img``

const CTA = styled(Button)`
  margin-top: 1.25rem;

  @media (max-width: 767px) {
    margin-top: 0.75rem;
  }
`
